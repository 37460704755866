.core_values{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .values_desktop{
        display: none;
    }
    .values_mobile{
        display: block;
        width: 90vw;
        .value{
            width: 90vw;
            height:fit-content;
            background-color: #01508B;
            color: #FEFEFF;
            padding: 10px;
            margin: 10px;
            .value_icon{
                    display: none;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .core_values{
        height: fit-content;
        .values_mobile{
            display: none;
        }
        .values_desktop{
            width: 80vw;
            height: fit-content;

            display: grid;
            grid-template-columns: 1fr 1fr;
            .contain{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                width: 90%;
                gap: 5px;
                .value{
                    width: 120px;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #01508B;
                    color: #FEFEFF;
                    padding: 10px;
                    margin: 10px;
                    cursor: pointer;
                    .value_icon{
                        display: block;
                        margin-bottom: 4px;
                        font-size: 20px;
                    }
                    .value_title{
                        font-size: 0.9rem;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    .value_description{
                        font-size: 1rem;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                }


            }
            .contents{
                background-color: aqua;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .value_icon{
                    background-color: #FEFEFF;
                    color: #01508B;
                    width:40% ;
                    height: 40%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .value_title{
                        font-size: 1.1rem;
                        font-weight: 600;
                        margin-bottom: 10px;

                    }
                }
   

            }
        }
    }
}