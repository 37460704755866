$primary-color: #082767;
$secondary-color: #fff;

.login {
  background-color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-form {
    background-color: $primary-color;
    padding: 30px;
    border-radius: 5px;
    color: $secondary-color;
    text-align: center;

    .login-title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .login-inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .login-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        label {
          font-size: 14px;
          margin-bottom: 5px;
        }

        input {
          padding: 10px;
          border: none;
          border-radius: 3px;
          background-color: lighten($primary-color, 20%);
          color: $secondary-color;

          &:focus {
            outline: none;
            background-color: lighten($primary-color, 10%);
          }
        }
      }
    }

    .login-button {
      button {
        padding: 10px 20px;
        border: none;
        border-radius: 3px;
        background-color: $secondary-color;
        color: $primary-color;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }
}
