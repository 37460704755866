  .contact_item {
            width: 90vw;
            height: 30vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color:#082767;
            color: #FEFEFF;
            border-radius: 5px;
            text-align: center;
            gap: 10px;
            a{
                color: #FEFEFF;
                text-decoration: none;
            }

            h6 {
                word-wrap: normal;
            }
            p {
                word-wrap: normal;
            }
            img{
                max-height: 30px;
            }
        }


@media only screen and (min-width: 768px) {
        .contact_items {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 80vw;
            gap: 20px;
            .contact_item {
                width: 16vw;
                height: 30vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                opacity: 1;
                transition: all 0.5s ease-in-out;
                &:hover{
                    opacity: 0.8;
                }

                a{
                    cursor: pointer;
                }
          
                img{
                    max-height: 25px;
                }
                
            }
        }
    }


