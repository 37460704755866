.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'muli';
}
.donate{
    .donate_button{
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 20px;
        padding: 10px;
        background-color: #01508B;
        color: #FEFEFF;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        transition: all 0.2s ease-in-out;
        //shaddow and elevation
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        z-index: 9999;


        &:hover{
            background-color: #fff;
            color: #01508B;
            border: 1px solid #01508B;
        }
    }
    .donate_form{
        //at the midle of the page on top of everything
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        color: #0C2C3E;
        font-size: 1.2rem;

        width: 400px;
        height: 400px;
        border-radius: 10px;
        padding: 20px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        //shaddow and elevation
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
      .close{
        button{
            position: absolute;
            top: 0;
            right: 0;
            margin: 20px;
            padding: 10px;
            background-color: #01508B;
            color: #FEFEFF;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            //shaddow and elevation
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
            
        }
      }
        
        img{
            width: 65px;
        }
    }
}