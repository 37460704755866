form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;

    .form_container{
        .input_container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                font-size: 14px;
                font-weight: bold;
                color: #082767;
            }
            input{
                border-radius: 5px;
                padding: 10px;
                background-color: aliceblue;
                border: none;
                outline: none;

            }
            margin-bottom: 20px;
        }
        .textarea_container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            label{
                
                font-size: 14px;
                font-weight: bold;
                color: #082767;
            }
            textarea{
                background-color: aliceblue;
                border-radius: 5px;
                border: none;
                outline: none;
                padding: 10px;
            }
        }
    }
    .submit{
        width: 200px;
        background-color: #082767;
        color: #FEFEFF;
        font-weight: bold;
        padding: 12px;
        border-radius: 20px;  
        transition: all 0.5s ease-in-out;    
    }
    .submit:hover{
        opacity: 0.8;
    }

}