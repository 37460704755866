.footer{
    display: flex;
    flex-direction:column ;
    align-items: center;
    height: fit-content;
    margin: 20px;
    .footer_container{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .copyright{
        width: 100vw;
        height: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: bold;
        background-color: #082767;
        p{
            color: #fff;
        }
    }
}
 .footer_links, .footer_services{
    display: none;

}

@media only screen and (min-width: 768px) {
    .footer{
        height:fit-content;
        .footer_container{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin: 20px;
            gap: 20px;
            height: 100%;
            h6{
                color: #082767;
            }
        }
      
        .footer_questions, .footer_links, .footer_services{
            display: block;
       
        }
    }
    


}