.about {
    height: fit-content;
    overflow: hidden;
    margin-bottom: 20px;
    .about_container {

        .about_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h3{
                color: #01508B;
            }
            .image{
                display: none;
                img{
                    min-height: 300px;

                }
            }
    
        }
    }

}
.treat{
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 35vw;

    div{
        background-color: #01508B;
        width: 60vw;
        height: fit-content;
        color: #FEFEFF;
        text-align: center;
        padding: 10px;

    }
}

.mission {
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .about {

        .about_container {
            .about_content {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: left;
                    text-align: left;
                    p{
                        font-size: 14px;
                    }
                }

                .image{
                    display: block;
                    img {
                        width:40vw;
                    }
                }
        
            }
            .treat{
                width: 90vw;
                margin-top: 20px;
                div{
                    left: 35vw;
                    width: 30vw;

                }     

            }
        }


    }

    .mission {
        margin-top: 12vh;
    p{
        font-size: 1.3rem;

    }
    }

}