.carousel {
    height: 100vh;
    margin-top: 10vh;
    color: #fff;
    overflow-y: hidden;
    font-size: 1.8rem;
    font-weight: bold;
    
}
.carousel_item {

    background-color: #FEFEFF;
    .container{
        display: flex;
        flex-direction: column-reverse;
        
        .image {
            height: 90vh;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

        }
        
        .content {
            z-index: 99;
            // background-color: rgba(255, 255, 255, 0.568);
            position: relative;
            top: -65vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 10px;
            font-size: 1.2rem;
            h1{
                color: #FEFEFF;
                font-size:2.8rem;
                font-weight: bold;
            }
            .button {
                margin-top: 80px;
                button{
                    background-color: #01508B;
                    color: #FEFEFF;
                    border: none;
                    border-radius: 5px;
                    font-size: 16px;
                    cursor: pointer;
                    //shaddow
                    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                    transition: all 0.2s ease-in-out;
                    z-index: 99999999;
                    a{  
                        padding: 10px 20px;

                        width: 100%;
                        height: 100%;
                        text-decoration: none;   
                        color: #FEFEFF;
                    }
                    

                    &:hover{
                        background-color: #fff;
                        color: #01508B;
                        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                        a{

                            color: #01508B;
                        }
                    }

                 
                    
                }
           
            }
        }

    }
    
}
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);

}
.carousel-indicators button
{
    background-color: black!important;
}
@media only screen and (min-width: 768px) {
    .carousel {
        margin-top:0 ;
        color: black;
    
    }
    .carousel_item {
        .container{
            display: grid;
            grid-template-columns: 1fr 1fr;
    
            
            .image {
                background-color: blue;
                
                width: 40vw;
                img {
                    width: 100%;
                }

            }
            .content {
                position: relative;
                top:0vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: left;
                gap: 20px;
                h1{
                    color: #01508B;
                    margin-left: 0;
                }
                
    

            }

        }
        
    }

    .carousel-control-next,
    .carousel-control-prev /*, .carousel-indicators */ {
        max-width: 100px !important;
    }



}
@media only screen and (max-width: 768px) {
    .carousel {
        .image {
            img{
                // filter: blur(5px);
                // -webkit-filter: blur(5px);

            }

        }
    
    }

}
@media only screen and (min-height: 1000px) {
    .carouusel{
        height: 80vh;
        background-color: aqua;
    }
}