
/*ADMIN NAV*/
.admin-nav {
    background-color: #082767;
    height: 15vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a{
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }
  
    .logo-title{
        height:100% ;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        img{
            width: 50px;
            height: 50px;
            margin-right: 1rem;

        }
        h2{
            display: inline-block;
        }

    }
    .signout-user{
        margin-left: auto;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        .sign-out{
            cursor: pointer;
            font-size: medium;

        }
        .user{
            margin-left:  1rem;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #fff;

        }

    }

}

/*ADMIN HEADER*/
.admin-header{
    background-color: #fff;
    height: 10vh;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .admin-header-link{
        text-decoration: none;
        color: #082767;
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0 1rem;
        cursor: pointer;
        border-bottom: 0.5px solid #082767;
        transition: all 0.3s ease-in-out;
        &:active{
            color: #082767;
            border-bottom: 2px solid #082767;
        }
        &:hover{
            color: #082767;
            border-bottom: 2px solid #082767;
        }
    }
    .link1{
        margin-left: auto;
    }
    .link2{
        margin-right: auto;
    }
  

}

/*ADMIN FOOTER*/
.admin-footer{
    background-color: #082767;
    height: 10vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    a{
        text-decoration: none;
        color: #fff;
        cursor: pointer;
    }
}
/*OUTLET*/
.outlet{
    min-height: 65vh;
}


//gellery item

$primary-color: #082767;
$secondary-color: #fff;

.gallery-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
    justify-content: space-evenly;
  gap: 10px;
  padding: 10px;
  background-color: $secondary-color;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 50vw;
  height: 200px;
  overflow: hidden;
  .gallery-item-img {
    max-width: 190px;
    max-height: 190px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .gallery-item-caption {
    font-size: 20px;
    color: $primary-color;
    margin-top: 10px;
  }
  .gallery-buttons {
    margin-top: 10px;
    button {
      background-color: $primary-color;
      color: $secondary-color;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      padding: 10px;
      cursor: pointer;
      &.delete {
        background-color: darken($primary-color, 10%);
      }
      &:hover {
        background-color: lighten($primary-color, 10%);
      }
    }
  }
}


//Careers
.gallery {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    
    .gallery-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      
      .gallery-head-title {
        font-size: 24px;
        font-weight: bold;
        color: #082767;
      }
      
      .gallery-head-btn {
        button {
          background-color: #082767;
          color: #fff;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          
          &:hover {
            background-color: #063152;
          }
        }
      }
    }


.gallery-body {
    display: flex;
    flex-direction: column;
    
      grid-gap: 20px;
      .gallery-item {
        position: relative;
        
        .gallery-item-img {
          height: 200px;
          overflow: hidden;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        
        .gallery-item-caption {
          font-size: 16px;
          color: #082767;
          margin-top: 10px;
        }
        
        .gallery-buttons {
          position: absolute;
          top: 10px;
          right: 10px;
          
          .gallery-btn {
            background-color: #fff;
            color: #082767;
            border: 1px solid #082767;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            
            &:hover {
              background-color: #082767;
              color: #fff;
            }
            
            &.delete {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  

