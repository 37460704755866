.services{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    overflow-x: hidden;
    font-size:1.5rem ;
    .services_container{
        display: flex;
        flex-direction:column;
        gap: 10px;
        padding: 10px;
        .service_container{
            overflow: hidden;
        }

    }
    
    
}
.service_image{
    display: none;
}


.service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 1rem 1rem;
    cursor: pointer;
    background: #fff;
    border-radius: 1px;
    border: 1px solid #ddd1f3;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.8s ease-in-out;
    
    
    &:hover {
        cursor: pointer;
        transform: scale(1.01);
        border: 1px solid #01508B;
        transition: all 0.8s ease-in-out;

    }
    
    .service_icon{
        width: 50px;
        height: 50px;
        background-color: #01508B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    
       

      

        }
    }
    .service_content{
        h3{
            font-size: 20px;
            font-weight: 600;
            color: #01508B;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color: #01508B;
        }
        a{
            text-decoration: none;
            color: #01508B;
            font-size: 14px;
            font-weight: 400;
        }
    }

@media only screen and (min-width: 768px) {
    .services{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        .services_container{
            width:80vw;
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 10px;  
            .service_container{
                width: 100%;
                display:grid;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                cursor: pointer;
                background: #fff;
                border-radius: 1px;
                border: 1px solid #ddd1f3;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
                transition: all 0.8s ease-in-out;
                
            }
          
        }
        
    }
    .service_image{
        display: block;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    .service{
        width: 100%;
        height: 100%;
        align-items: center;
        cursor: pointer;
        background: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
        box-shadow:none;
        &:hover {
            cursor: pointer;
            transform: scale(1.0);
            border: 0px solid #01508B;
            transition: all 0.8s ease-in-out;
    
        }
        
    }
    
}