.contact {
    margin-top: 30px;

    .contact_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

    }
}

.map_form{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}



@media only screen and (min-width: 768px) {
    .contact {
        margin-top: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contact_items {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 80vw;
            gap: 20px;
        }
    }


    .map_form{
        width: 100%;
        display: grid;
        
        grid-template-columns: 1fr 1fr;
    }
}