.google_map_code {
    padding: 20px;
    iframe {
        width: 90vw;
        height: 50vh;
        border: 0;
    }
}

@media only screen and (min-width: 768px) {
    .google_map_code {
        padding: 20px;
        iframe {
            width: 45vw;
            height: 60vh;
            border: 0;
        }
    }
}
