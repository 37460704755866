.footer_links{
    ul{
        list-style: none;
        li{
            margin: 10px 0;
            a{
                color: #000;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                &:hover{
                    color: #082767;
                }
                img{
                    max-height: 25px;
                    margin-right: 10px;
                }
            }
        }
    }
}