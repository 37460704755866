.footer_about{
    height: fit-content;
    .social{
        display: flex;
        gap: 18px;


    }
    img{
        max-height: 15px;
    }
}
