.gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FEFEFF;
    color: #082767;
    font-weight: bold;
    margin-bottom:0 ;

}

.gallery_content{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //rows height
    grid-auto-rows: 300px;
    gap: 2px;
    padding: 20px;
    border-radius: 10px;


    .image-container{
        width: 100%;
        height: 100%;
        display: flex;             

        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img{
            width: 100%;
            height: calc(100% - 30px);
            object-fit: cover;
            border-radius:6px 6px 0px 0px;
            img{
                background-color: #FEFEFF;

            }

        }
        .image-caption{
            overflow: hidden;
            width: 100%;
            height: 30px;
            margin-top: -30px;
            opacity: 0;
            transition: all 0.2s ease-in-out;

        }
        &:hover{
            .image-caption{
                background-color: #FEFEFF;
                color: #082767;
                align-items: center;
                text-align: center;
                z-index: 1;
                opacity: 1;

    
            }
        }
        
    }
}

@media only screen and (max-width: 768px) {
    .gallery_content{
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 200px;
        gap: 20px;
        padding: 50px;
        background-color: #FEFEFF;
        border-radius: 10px;
        margin-top: 20px;

    }
}

@media only screen and (max-width: 500px) {
    .gallery_content{
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 200px;
        gap: 20px;
        padding: 50px;
        border-radius: 10px;
        margin-top: 20px;


        .image-container{
            width: 100%;
            height: 100%;
            display: flex;             

            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img{
                width: 100%;
                height: calc(100% - 30px);
                object-fit: cover;
                border-radius:6px 6px 0px 0px;
            }
            .image-caption{
                overflow: hidden;
                width: 100%;
                height: 30px;
                background-color: #FEFEFF;

                margin-top: -30px;
                opacity: 1;
                transition: all 0.2s ease-in-out;
                background-color: #FEFEFF;
                color: #082767;
                align-items: center;
                text-align: center;

            }
            &:hover{
                .image-caption{
                    background-color: #FEFEFF;
                    color: #082767;
                    align-items: center;
                    text-align: center;
                    z-index: 1;
                    opacity: 1;

        
                }
            }
            
        }
    }
}