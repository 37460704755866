.board{
    color: #fff;
    .board-header{
        h1{
            font-size: 2rem;
            font-weight: 700;
            color: #01508B;
            text-align: center;
        }
    }
    .board-members{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        
      
        .board-member{
            margin: 1rem;
            overflow: hidden;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #01508B;
            cursor: pointer;
            padding: 1rem;
            .member_image{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

            }
            
            .member-name{
                margin-top: 1rem;
                font-size: 1.2rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

}


//small screen
@media only screen and (max-width: 768px) {
    .board{
        .board-members{
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;
            gap: 1rem;
            .board-member{
                width: 90%;
                .member_image{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}