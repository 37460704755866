.fullTenderDisplay {
    border-radius: 10px;
    padding: 20px;
    background: rgba(182, 173, 173, 0.156);
    padding-bottom: 50px;
    margin-bottom: 100px;
   
}

.singleTenderImg{
    margin-left: 300px;
    margin-right: 300px;
    border-radius: 10px;
    align-items: center;
    object-fit: contain;
    display: flex;
    height: 500px;
   
}

.singleTenderWrapper{
    width: 100%;
    margin: 10px;
}

.singleTenderViewInfo{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

}

.singleTenderTitle{
    text-align: center;
    margin: 10px;
    font-size: 26px;
    font-weight: 600;
    
}

.singleTenderViewICategory{
    background-color: tomato;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    color: white;
    justify-content: space-between;
    
}

.singleTenderViewICategoryDate{
    background-color: teal;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    color: white;
}

.singleTenderViewICategoryRegion{
    background-color:chocolate;
    margin: 10px;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    color: white;
}

.singleTenderViewICategoryArea{
    background-color:slategrey;
    margin: 10px;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    color: white;

}

.singleTenderDescription{
    color: #444;
    line-height: 30px;
    font-size: 18px;


}

.singleTenderDescription::first-letter{
    margin-left: 20PX;
    font-size: 30px;
    font-weight: 600px;
}

.applyBtn{

    margin-top: 10px;
    background-color: #572250;
    color: #efefef;
    border-radius: 10px;
    cursor: pointer;
    block-size: 50px;
    width: 15%;
    padding-top: 15px;
    padding-bottom: 20px;
    border: none;

}


