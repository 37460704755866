.navigation {
    background-color: #FEFEFF;
    font-size: 20px;
    font-family: 'muli';

}
.logo {
    img{
        max-height: 40px;
    }
}
.nav_container {
    background-color: #FEFEFF;
}
.navbar-toggler,
.navbar-toggler,
.navbar-toggler-icon
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none!important;
    box-shadow: none!important;
    border: 0px!important;
}
.nav_link{
    color: #01508B!important;
    font-weight: bolder!important;
    transition: all 0.5s ease-in-out!important;
    border-bottom: 2px solid transparent;
}

.nav_link:hover{
    border-bottom: 2px solid #01508B!important;
    color: #CCCFFC!important;
}
@media only screen and (min-width: 768px) {
    .navigation {
        background-color: transparent;
    
    }
    .navigation1{
        background-color: #FEFEFF;
    }

    .nav_container {
        display: flex;
        justify-content: space-between;
        gap: 21vw;
        background-color: transparent;
    }
    .logo {
        img{
            max-height: 75px;
        }
    }
    .home_link{
        color: black !important;
    }

}

.signout-user{
    margin-left: auto;
    font-weight: bold;
    display: flex;
    align-items: center;
    .sign-out{
        cursor: pointer;
        font-size: medium;

    }
    .user{
        margin-left:  1rem;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;

    }

}


