.section_heading {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;    
    align-items: center;
    text-align: center;
    .upper{
        color: #082767;
        text-transform: uppercase;
        font-size:1.2rem;

    }
    .main{
        font-size: 1.6rem;

    }
}